var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"modal-id":_vm.userAndRoles.users.inviteModalId,"title":_vm.modalTitle,"preloader":_vm.preloader,"modal-scroll":false,"hide-submit-button":!_vm.isMailSettingExist,"cancel-button-text":_vm.isMailSettingExist ? _vm.$t('cancel') : _vm.$t('close')},on:{"submit":_vm.submit,"closeModal":_vm.closeModal}},[_c('template',{slot:"body"},[(_vm.preloader)?_c('app-overlay-loader'):_vm._e(),_vm._v(" "),(_vm.isMailSettingExist || _vm.hasData)?_c('form',{ref:"form",class:{'loading-opacity': _vm.preloader},attrs:{"data-url":_vm.hasData ? `admin/auth/users/attach-roles/${_vm.userAndRoles.rowData.id}` : `/admin/auth/users/invite-user`}},[(!_vm.hasData)?_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mb-0",attrs:{"for":"userEmail"}},[_vm._v("\n                    "+_vm._s(_vm.$t('email'))+"\n                ")]),_vm._v(" "),_c('app-input',{staticClass:"col-sm-9",attrs:{"id":"userEmail","type":"email","placeholder":_vm.$placeholder('email'),"required":true},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group row align-items-center mb-0"},[_c('label',{staticClass:"col-sm-3 mb-0",attrs:{"for":"roles"}},[_vm._v("\n                    "+_vm._s(_vm.$t('role'))+"\n                ")]),_vm._v(" "),_c('app-input',{staticClass:"col-sm-9",attrs:{"id":"roles","type":"search-select","placeholder":_vm.$chooseLabel('role'),"list":_vm.roleLists,"list-value-field":"name","isAnimatedDropdown":true,"required":true},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)]):_c('app-note',{attrs:{"title":_vm.$t('no_delivery_settings_found'),"notes":`<ol>
                 <li>${_vm.$t('cron_job_settings_warning',{
                  documentation: `<a href='https://jobpopint.gainhq.com/documentation/important-settings.html' target='_blank'>${_vm.$t('documentation')}</a>`
              })}</li>
                 <li>${_vm.$t('no_delivery_settings_warning', {
                  location: `<a href='${_vm.urlGenerator(_vm.JOB_POINT_EMAIL_SETUP_SETTING)}'>${_vm.$t('here')}</a>`
              })}</li>
              </ol>`,"content-type":"html"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }