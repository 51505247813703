export default {

    state: {
        darkMode: false
    }

    // getters: {
    // },

    // actions: {

    // },

    // mutations: {

    // }
}
