<template>
    <div class="position-absolute overlay-loader-wrapper">
        <span class="spinner-bounce d-flex align-items-center justify-content-center h-100">
            <span class="bounce1 mr-1"/>
            <span class="bounce2 mr-1"/>
            <span class="bounce3 mr-1"/>
            <span class="bounce4"/>
        </span>
    </div>
</template>

<script>
    export default {
        name: "OverlayLoader"
    }
</script>
